/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  z-index: 997;
  transition: all 0.5s;
  padding: 22px 0;
  background: #fff;

  &.header-scrolled {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.10);
    padding: 12px 0;
  }

  .logo {

    h1 {
      font-size: 30px;
      margin: 0;
      padding: 0;
      line-height: 1;
      font-weight: 700;
      letter-spacing: 1px;
    }

    h1 a, h1 a:hover {
      color: $secondary;
      text-decoration: none;
    }

    img {
      padding: 0;
      margin: 0;
      max-height: 40px;
    }
  }
}
