/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 70vh;
  margin-top: 70px;

  h1 {
    margin: 0 0 10px 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: darken($secondary, 15);
  }

  h2 {
    color: lighten($secondary, 15);
    margin-bottom: 50px;
    font-size: 24px;
  }

  .btn-call-to-action {
    font-family: $font-primary;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 30px;
    border-radius: 50px;
    transition: 0.5s;
    color: $primary;
    border: 2px solid $primary;
    &:hover {
      background: $primary;
      color: $white;
    }
  }

  .animated {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
  }

  @media (min-width: 1024px) {
    background-attachment: fixed;
  }

  @media (max-width: 991px) {
    height: 100vh;

    .animated {
      animation: none;
    }

    .hero-img {
      text-align: center;
      img {
        width: 50%;
      }
    }
  }

  @media (max-width: 768px) {
    margin-top: 20px;
    h1 {
      font-size: 28px;
      line-height: 36px;
    }
    h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }

    .hero-img img {
      width: 70%;
    }

  }

  @media (max-width: 575px) {

    .hero-img img {
      width: 80%;
    }

  }

}

 @keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}
