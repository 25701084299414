/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: $white;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  padding: 30px 0; /* This value needs to match body's margin at bottom */
  color: $secondary;
  font-size: 14px;
  position: absolute;
  bottom: 0;
  width: 100%;

  .credits {
    font-size: 13px;
    padding-top: 5px;
    color: $secondary;
  }

  .footer-links {
    a {
      color: $secondary;
      padding-left: 15px;
      &:first-child {
        padding-left: 0;
      }
      &:hover {
        color: $primary;
      }
    }
  }
}
